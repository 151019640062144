<template>
  <div class="container mt-4">
      <span>Подписка успешно настроена, можете закрыть окно и продложить работу с Ботом!</span>
      <div v-if="loading">
        <div class="spinner-grow" role="status"></div>
        <span class="visually-hidden">Настройка подписки...</span>
      </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'


export default {
  mounted() {
    // await this.$store.dispatch('getUserInfo')
    // const userInfo = this.$store.getters.userInfo
    // await this.$store.dispatch('confirmUserPayment', this.$route.query.OrderId)

    // if (userInfo) {
    //   /* Проверяем была ли завершена регистрация, 
    //   если нет, то отправляем на этап регистрации */
    //   if (!userInfo.regCompleted) {
    //     await this.$store.dispatch('userRegCompleted');
    //     this.$router.push('/registration');
    //   }
    // }
    this.loading = true
    var db = firebase.database()
    const dbRef = db.ref()

    // Снимаем данные о номере платежа
    const paymentId = this.$route.query.MERCHANT_ORDER_ID    

    // Ищем платеж в базе и при успехе, записываем, что он оплачен и переносим к пользователю
    var orderRef = db.ref('orders')
    orderRef.once('value', (snapshot) => {
      var data = snapshot.val()
      console.log(data)
      if (paymentId in data) {
        var uid = data[paymentId]['uid']
        var updates = {}
        // Устанавливаем флаг об успешной оплате
        var timestamp = Date.now()
        data[paymentId]['paySucceed'] = true
        data[paymentId]['timestamp'] = timestamp
        updates[`/orders/${paymentId}/paySucceed/`] = true
        updates[`/orders/${paymentId}/timestamp/`] = timestamp
        // Копирум данные успешной транзакции пользователю
        updates[`/users/${uid}/orders/${paymentId}`] = data[paymentId]
        // updates[`/users/${uid}/orders/${paymentId}/timestamp`] = timestamp
        // Обновляем срок оплаченной подписки
        updates[`/users/${uid}/subscription_till`] = parseInt((Date.now() / 1000).toFixed(0)) + (data[paymentId]['durationDays'] * 24*60*60)
        dbRef.update(updates)
      } else {
        window.console.log('Payment not found!!!')
      }
    })

    this.loading = false
  },
  data: () => ({
    loading: false,
  }),
}
</script>

<style lang="scss" scoped>
.fs-error {
  font-size: 5rem;
}

@media (max-width: 950px) {
  .fs-error {
    font-size: 4rem !important;
  }
}

@media (max-width: 500px) {
  .fs-error {
    font-size: 2rem !important;
  }
}

</style>